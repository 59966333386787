<script>
    let className = ''
    export { className as class }
</script>

<svg class={className} xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="none" stroke-linecap="square" stroke-miterlimit="10" style="--darkreader-inline-stroke: none;" data-darkreader-inline-stroke="" viewBox="75.7 138.3 812.27 256.32">
  <clipPath id="p.0"><path d="m0 0l960.0 0l0 540.0l-960.0 0l0 -540.0z" clip-rule="nonzero"/></clipPath>
  <g clip-path="url(#p.0)">
    <path fill="#e2712e" d="m109.98294 242.4421l0 7.194565l744.2272 0l0 -7.194565z" fill-rule="evenodd" style="--darkreader-inline-fill: #aa4e18;" data-darkreader-inline-fill=""/>
    <path fill="#9a182d" fill-rule="evenodd" style="--darkreader-inline-fill: #7b1324;" data-darkreader-inline-fill="" d="m129.70268 249.63518l-53.984833 144.0461l6.752014 0l65.42575 -144.0461z"/>
    <path fill="#9a182d" fill-rule="evenodd" style="--darkreader-inline-fill: #7b1324;" data-darkreader-inline-fill="" d="m815.7633 249.63518l65.39447 138.0461l6.783325 0l-53.891113 -138.0461z"/>
    <path fill="#763a6b" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill="" d="m223.16788 195.51375c-1.3751678 0 -2.6798248 0.8815155 -3.102951 2.1509094l-5.359665 42.101517c-0.42312622 1.8335571 0.8462677 3.102951 2.67984 3.102951l61.74182 0c1.304657 0 2.6798096 -0.8462677 3.1029663 -2.2566986l5.3948975 -41.995728c0.42312622 -1.7982941 -0.88150024 -3.102951 -2.6798096 -3.102951z"/>
    <path fill="#9a182d" fill-rule="evenodd" style="--darkreader-inline-fill: #e7677c;" data-darkreader-inline-fill="" d="m224.47246 196.35664l-4.8307343 38.046494l58.53308 0l4.9365234 -38.046494z"/>
    <path fill="#763a6b" d="m189.14156 237.08272l0 5.7828217l54.125473 0l0 -5.7828217z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m733.93005 194.9815c-1.8335571 0 -3.2440186 1.3751831 -3.2440186 3.1029663l5.923828 42.1015c0 1.4104309 1.3046265 2.2566986 2.6798096 2.2566986l61.74182 0c1.7277832 0 3.1382446 -1.4104462 3.1382446 -3.1029663l-5.818054 -42.1015c0 -1.304657 -1.3751831 -2.2566986 -2.6798096 -2.2566986z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#9a182d" d="m735.19934 198.08441l5.3948975 38.11702l58.603577 0l-5.359619 -38.11702z" fill-rule="evenodd" style="--darkreader-inline-fill: #e7677c;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m846.1287 187.36523l-10.71936 49.294724l28.667114 0.42314148c0 0 -5.359619 -33.6389 -7.616333 -40.267944c-2.2567139 -7.193222 -10.331421 -9.449921 -10.331421 -9.449921z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m796.97565 369.9097l2.2214355 7.193207l-9.449951 8.03949c0 0 2.0803833 0.2821045 4.442871 0.2821045c1.7630615 0 3.7023926 -0.14105225 5.00708 -0.7052307c3.1381836 -1.3751831 12.059204 -1.3751831 12.059204 -1.3751831l-3.5613403 -13.434387z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m808.0476 378.30173c-1.6220093 0 -3.8081665 0.28207397 -5.712219 1.480957c-3.3497925 2.115631 -4.795532 5.3948975 -10.155151 5.3948975c-0.3526001 0 -0.6699829 0 -1.022583 -0.035247803c-0.84625244 -0.42312622 0 -0.84625244 0 -0.84625244l0 0c0 0 -5.359619 0.42312622 -6.24115 3.1029358c0 0.91677856 5.571228 1.2341309 11.741882 1.2341309c6.4174805 0 13.4696045 -0.3526001 15.550049 -0.7757263c1.3046265 -1.8335571 -1.8336182 -9.344147 -1.8336182 -9.344147c0 0 -0.95202637 -0.21154785 -2.3272095 -0.21154785z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m796.65845 315.0088c-6.6290894 0 -13.540222 18.723541 -13.540222 18.723541l14.703796 39.386414l10.296204 -0.95202637c0 0 2.2567139 -25.458374 -4.9365234 -48.76581c-1.939331 -6.1354065 -4.2313232 -8.39212 -6.5232544 -8.39212z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m855.5786 368.18195l-10.296204 0.42312622l2.2567139 6.6643066l-9.873047 8.603668c0 0 1.9041138 0.24682617 4.2313232 0.24682617c1.9040527 0 4.0549927 -0.17630005 5.6417236 -0.77575684c2.0803833 -1.1636047 6.7348022 -1.3399048 9.590942 -1.3399048c1.4457397 0 2.4330444 0.035247803 2.4330444 0.035247803l-3.984497 -13.857513z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m855.896 376.78552c-1.480957 0 -3.420288 0.3526001 -5.253845 1.7277832c-3.3497925 2.0098572 -4.760193 5.3948975 -10.54303 5.3948975c-0.31732178 0 -0.6699219 0 -1.0578003 -0.035247803c-0.95202637 -0.52893066 0 -0.9873047 0 -0.9873047l0 0c0 0 -4.9365234 0.45837402 -5.782776 3.138214c-0.24682617 0.7757263 3.984497 1.0578308 9.167847 1.0578308c6.911133 0 15.585266 -0.52893066 17.66565 -1.0578308c1.3046875 -1.304657 -1.8335571 -8.92099 -1.8335571 -8.92099c0 0 -0.95202637 -0.3173523 -2.3624878 -0.3173523z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m844.8241 313.45734c-6.593811 0 -13.822266 18.864563 -13.822266 18.864563l15.126892 38.998535l10.331482 -0.88150024c0 0 2.2566528 -25.105774 -5.3949585 -48.871613c-1.7982788 -5.88855 -3.984436 -8.109985 -6.24115 -8.109985z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#e16e74" d="m815.31177 255.101c-2.3272095 0 -4.196045 0.105789185 -5.35968 0.3173523c0 0 -30.923767 7.087448 -40.267883 23.765823c-8.497925 17.383636 9.344116 58.497833 12.975952 59.908264c1.9041138 0.6699524 17.947815 3.7023926 35.225647 3.7023926c15.126953 0 31.170654 -2.2919617 39.527466 -10.472473c-0.95202637 -9.767273 -12.552856 -33.497864 -11.283508 -33.497864c1.4104614 0 10.331482 -8.92099 10.754578 -21.05075c0.52893066 -12.023956 -4.8307495 -20.098694 -4.8307495 -20.098694c0 0 -24.61206 -2.574051 -36.74182 -2.574051z" fill-rule="evenodd" style="--darkreader-inline-fill: #811b20;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m821.7638 144.62929c-2.5388184 0 -4.8307495 0.6347046 -6.452759 2.0098724c0 0 -4.5134277 13.857529 -1.7982788 21.50914c1.3751221 3.1029663 3.631836 1.7983093 4.9365234 2.67984c0.84625244 1.3751678 -2.2567139 18.794052 -2.2567139 18.794052l17.48938 0.9520416c0 0 8.03949 -24.153717 7.0874634 -33.180496c-0.6347046 -6.734833 -11.107178 -12.76445 -19.005615 -12.76445z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m847.9627 198.08441l-42.52466 2.6798248c0 0 3.1029663 30.500671 9.449951 39.844803c0 0 -4.9365234 9.873062 -4.9365234 14.809586c0 0 17.066223 2.4329987 34.167786 2.4329987c4.8307495 0 9.696716 -0.21157837 14.139587 -0.7052307c-11.565552 -46.473846 -10.296143 -59.06198 -10.296143 -59.06198z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m819.2955 185.21426c-5.782776 0 -6.24115 3.9492188 -6.24115 3.9492188l-1.7983398 22.355408l37.12976 4.513397c0 0 4.5133667 -23.73059 0.4230957 -27.291931c-3.9492188 -3.5260925 -23.624817 -3.5260925 -29.513367 -3.5260925z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m814.25433 186.06056c-1.0578613 0 -5.6065063 0.3173523 -8.815247 5.818054c-3.984497 5.782776 -11.60083 27.679794 -11.60083 27.679794c0 0 -30.394897 -16.537354 -41.643127 -18.794052l-12.905457 -5.782791l0 0c0 0 -1.3751831 3.1029663 0.84625244 5.3596497c2.715088 2.2566986 8.074707 3.6671448 8.074707 3.6671448l-18.37091 2.6798248c0 0 4.090271 6.6643066 7.616394 8.462616c1.4456787 0.70521545 4.231262 0.91677856 7.052124 0.91677856c4.2666016 0 8.638977 -0.45838928 8.638977 -0.45838928c0 0 28.878662 25.705185 47.531677 25.705185c1.0578003 0 2.0803833 -0.105773926 3.0324097 -0.28208923c12.023987 -4.8307343 22.355408 -42.947754 19.252441 -48.730545c-3.1381836 -5.818054 -8.497864 -6.2411804 -8.497864 -6.2411804c0 0 -0.070495605 0 -0.21154785 0z" fill-rule="evenodd" style="--darkreader-inline-fill: #6f1c0a;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m815.4879 185.28484c-3.2440186 0 -8.497864 1.1283417 -12.30603 7.440048c-5.782837 9.449905 -9.767273 26.4104 -9.767273 26.4104c0 0 -13.434448 -7.6163483 -16.537354 -8.921005c0 0 -9.026794 11.177704 -7.1932373 19.217194c0 0 18.582458 13.504913 30.782776 13.504913c1.3046265 0 2.5387573 -0.14103699 3.7023926 -0.49365234c9.344116 -4.513397 19.64032 -33.603622 19.64032 -33.603622c0 0 7.1931763 -18.794067 -5.35968 -23.201675c0 0 -1.1988525 -0.3526001 -2.961914 -0.3526001z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m793.41425 222.238c0 0.56417847 0 7.193222 3.5612793 12.165009l1.3751831 -0.88153076c-1.8335571 -1.8335571 -2.6798096 -4.9365234 -3.2087402 -7.193222c0 -0.84625244 0 -2.2566833 -0.4230957 -2.6798248l0 -0.84625244l0 -0.56417847z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m741.01746 207.95747l-8.9210205 0.88153076l0 1.3751831l9.344116 -0.8462677l-0.4230957 -1.4104462z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m741.96936 211.09563l-7.193115 0.9520569l0 1.304657l7.193115 -0.88153076l0 -1.3751831z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m743.69714 213.35233l-5.782776 1.3751526l0.42315674 1.304657l5.782776 -1.304657l-0.42315674 -1.3751526z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m749.48004 203.021l-19.64032 2.6798553l0 1.4104462l19.64032 -2.6798553l0 -1.4104462z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m753.147 209.36786c-0.9520874 1.304657 -0.9520874 3.1029663 -0.9520874 4.407608l0 1.8335724l1.3752441 -0.45838928l0 -0.42314148l0 -0.9520416c0 -1.3046417 0 -3.102951 0.4230957 -3.9844818l-0.84625244 -0.42312622z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m818.76654 177.95065c-0.49365234 0 -0.88153076 0.03526306 -1.1988525 0.07052612c-3.1029663 0.42312622 -3.1029663 8.03949 -3.1029663 8.03949l25.458374 3.102951c0 0 1.3751831 -4.9365234 0 -7.193222c-0.81103516 -1.9393463 -15.937927 -4.019745 -21.156555 -4.019745z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m827.05286 138.31767c-6.382202 0 -11.671326 3.0677032 -13.540161 7.8984375l0 1.4104462c0.4230957 3.102951 2.2214355 11.60083 8.462585 18.265137l-2.150879 14.80957c0 0 8.180542 2.2214355 17.348328 2.2214355c6.699585 0 13.928101 -1.1988678 18.864624 -5.3243866c-1.3046875 -9.449921 -3.984497 -27.291931 -15.691101 -34.90828c-4.442871 -3.0324402 -9.097351 -4.37236 -13.293396 -4.37236z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m823.3858 202.17468c0 0 -0.4230957 1.2693939 -1.4104004 3.5260925c-0.84625244 3.138214 -2.6798706 8.074738 -4.407654 13.011261c-1.8335571 4.9365234 -3.631836 9.449921 -5.359619 12.1297455l1.3046265 0.8462677c2.2214355 -4.055008 4.9365234 -11.142441 7.1932373 -17.489395c2.2567139 -6.2411804 3.9492188 -11.600845 3.9492188 -11.600845l-1.2694092 -0.42312622z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m813.93585 240.18571c0 0 -0.88153076 2.67984 -2.150879 6.2411804c-0.52893066 1.3751831 -0.9873047 3.2087402 -1.8335571 4.9365234c-0.42315674 1.3751831 -0.42315674 2.67984 -0.8463135 3.6318817l0 1.304657l0 0.42312622l0 0.42312622c0 0.52890015 0.42315674 0.52890015 0.42315674 0.52890015l0.42315674 0l0.4230957 -0.52890015c0 -0.14105225 0 -0.28207397 0.03527832 -0.42312622l4.901245 0l0 -1.304657l-4.5133667 0c0 -0.9520416 0.45837402 -2.2214355 0.9873047 -3.6318665c0.4230957 -2.1509247 1.2693481 -4.8307495 2.150879 -7.087448c0.9520874 -2.2566986 1.3751831 -4.0902557 1.3751831 -4.0902557l-1.3751831 -0.42314148z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m827.8991 184.68544c-6.7700806 0 -13.434387 0.9520569 -13.434387 0.9520569l0 1.2693939l1.2694092 0c2.2566528 -0.42312622 7.2284546 -0.8462677 12.164978 -0.8462677c3.1029663 0 6.2059326 0 8.462585 0.42314148l0 -1.2694092c-2.2566528 -0.5289154 -5.359619 -0.5289154 -8.462585 -0.5289154z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill="" d="m800.50165 296.99066c-0.84625244 0 -1.3046875 0.42312622 -1.3046875 1.4104309l-6.2058716 88.2157c0 0.84628296 0.84625244 1.2694092 1.2694092 1.2694092c0.17633057 0.105773926 0.31732178 0.14102173 0.49365234 0.14102173c0.7052002 0 1.3399048 -0.7052002 1.3399048 -1.4104309l5.817993 -86.79257c0 -0.88153076 -0.45837402 -1.8335571 -1.4104004 -1.8335571z"/>
    <path fill="#763a6b" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill="" d="m822.5043 296.99066c-0.9520874 0 -1.3751831 0.42312622 -1.3751831 1.4104309l-8.074768 89.200195c0 0.95202637 0.45837402 1.3751526 1.4104614 1.3751526c0.14105225 0.07052612 0.28204346 0.10580444 0.4230957 0.10580444c0.59942627 0 0.9520874 -0.7052307 1.3046875 -1.480957l7.616333 -89.200195c0 -0.9873047 -0.42315674 -1.4104309 -1.3046265 -1.4104309z"/>
    <path fill="#763a6b" d="m861.81946 296.99066c-0.84625244 0 -1.3046265 0.42312622 -1.3046265 1.4104309l7.6516113 87.200195c0.3526001 0.7757263 0.7052002 1.480957 1.2693481 1.480957c0.14105225 0 0.2821045 -0.03527832 0.42315674 -0.10580444c0.9873047 0 1.4104004 -0.42312622 1.4104004 -1.3751526l-8.039429 -87.200195c0 -0.9873047 -0.45843506 -1.4104309 -1.4104614 -1.4104309z" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill=""/>
    <path d="m826.9118 211.51872c-2.2566528 0 -4.407593 1.3751831 -4.407593 3.6318817l-10.719299 79.58383c-0.52893066 2.6798096 1.7277832 4.9365234 4.407593 4.9365234l52.397705 0c2.2567139 0 3.984497 -1.7277832 4.407593 -3.9492188l10.719299 -79.266495c0.52893066 -2.6798248 -1.6925049 -4.9365234 -4.407593 -4.9365234z" fill-rule="evenodd" style="--darkreader-inline-fill: #aa4e18;" data-darkreader-inline-fill="" fill="#2727AB"/>
    <path d="m795.671 292.58298c-3.6671143 0 -6.7700806 3.1382446 -6.7700806 7.087494l61.74182 0l0 -7.087494z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill="" fill="#2727AB"/>
    <path fill="#f9b345" d="m239.28183 195.40462l2.2566986 22.002792l12.023972 0l1.8335724 -22.002792z" fill-rule="evenodd" style="--darkreader-inline-fill: #f9b64b;" data-darkreader-inline-fill=""/>
    <path fill="#f9b345" d="m237.44824 191.87862l0 3.526123l20.204498 0l0 -3.526123z" fill-rule="evenodd" style="--darkreader-inline-fill: #f9b64b;" data-darkreader-inline-fill=""/>
    <path fill="#f9b345" d="m241.96162 188.74037l0 3.13826l10.754578 0l0 -3.13826z" fill-rule="evenodd" style="--darkreader-inline-fill: #f9b64b;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m239.28183 201.32849l0 8.0394745l16.114243 0l0 -8.0394745z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m148.87419 375.2693l-3.9844818 13.963318c0 0 1.6925201 -0.105773926 3.8786926 -0.105773926c2.8561401 0 6.593796 0.17630005 8.603668 0.95202637c1.2693939 0.7404785 2.9971771 0.9520569 4.654434 0.9520569c2.5035248 0 4.7954865 -0.49365234 4.7954865 -0.49365234l-9.449921 -8.074738l1.7983093 -6.6643066l-10.296188 -0.52893066z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m148.34525 383.66132c-1.3046417 0 -2.1509094 0.21154785 -2.1509094 0.21154785c0 0 -3.138214 7.5105896 -1.7277832 9.344147c1.9040833 0.45840454 9.767273 0.9520569 16.537354 0.9520569c5.747528 0 10.754578 -0.35263062 10.754578 -1.3751831c-1.4104309 -2.2566833 -6.3469696 -3.138214 -6.3469696 -3.138214l0 0c0 0 0.9520569 0.88153076 0 0.88153076c-0.42312622 0 -0.8109894 0.035247803 -1.1988678 0.035247803c-5.641739 0 -7.087433 -2.891388 -10.401962 -5.430176c-1.9040833 -1.198883 -3.9844818 -1.480957 -5.465439 -1.480957z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m166.36353 271.9556c-0.42312622 2.715088 -1.3751678 5.818054 -2.2566986 9.344116c1.8335724 7.1932373 0 13.963318 -5.3596497 17.101532c-1.8335724 5.7828064 -4.090271 10.71933 -5.782791 14.28067c-2.2566986 5.7828064 -4.0902557 13.399139 -4.513397 19.64032c-0.8815155 11.7066345 -2.6798248 42.1015 -2.6798248 43.476685c4.6191864 1.3399048 8.03949 1.6572571 10.331451 1.6572571c2.3624725 0 3.4908142 -0.3526001 3.4908142 -0.3526001c0 0 34.062027 -83.638855 25.141022 -94.81656c-3.6318665 -4.0902405 -10.719315 -7.193207 -18.370926 -10.331421z" fill-rule="evenodd" style="--darkreader-inline-fill: #aa4e18;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m192.80869 375.2693l-3.6671295 13.963318c0 0 1.6925201 -0.105773926 3.878708 -0.105773926c2.8561249 0 6.4879913 0.17630005 8.251038 0.95202637c1.2341309 0.7404785 2.961914 0.9520569 4.5839233 0.9520569c2.4682617 0 4.7602234 -0.49365234 4.7602234 -0.49365234l-9.344147 -8.074738l2.2566986 -6.6643066l-10.719315 -0.52893066z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m192.66766 383.66132c-1.2693939 0 -2.1156464 0.21154785 -2.1156464 0.21154785c0 0 -3.6671295 7.5105896 -2.2566986 9.344147c2.1509094 0.45840454 10.08461 0.9520569 16.854706 0.9520569c5.712265 0 10.613525 -0.35263062 10.401962 -1.3751831c-0.8462677 -2.2566833 -6.2059174 -3.138214 -6.2059174 -3.138214l0 0c0 0 0.8462677 0.88153076 0 0.88153076c-0.42312622 0 -0.7757416 0.035247803 -1.1636047 0.035247803c-5.359665 0 -6.6995697 -2.891388 -10.014099 -5.430176c-1.9746094 -1.198883 -4.019745 -1.480957 -5.500702 -1.480957z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#e2712e" d="m160.54584 260.53113c-14.421692 0 -31.31166 1.5514526 -31.31166 1.5514526c0 0 -6.770096 17.101532 7.6163483 28.667114c13.857529 11.60083 39.28064 9.908325 48.730545 10.754578c8.9210205 0.84625244 14.739059 3.1029663 14.739059 3.1029663c0 0 -6.6643066 16.114227 -7.5105743 27.715057c-0.9873047 11.706604 -2.2566986 42.1015 -2.2566986 43.476685c4.337097 1.3399048 7.7574005 1.6572571 10.119888 1.6572571c2.4329987 0 3.7023926 -0.3526001 3.7023926 -0.3526001c0 0 34.06201 -83.638855 24.612091 -94.81656c-10.754562 -11.177704 -51.022507 -20.204498 -51.022507 -20.204498c-3.5613403 -1.1636047 -10.190399 -1.5514526 -17.418884 -1.5514526z" fill-rule="evenodd" style="--darkreader-inline-fill: #aa4e18;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m163.54276 150.16525c-7.8631744 0 -18.01831 5.959091 -18.653015 12.588135c-0.42312622 9.449921 7.087448 33.603638 7.087448 33.603638l17.947784 -0.9520569c0 0 -3.5613403 -17.806747 -2.2566986 -18.794052c1.3751831 -1.2693939 3.1029663 0 4.9365387 -3.102951c2.2566833 -7.6163635 -2.2566986 -21.05075 -2.2566986 -21.05075c-1.6925201 -1.5867462 -4.125534 -2.2919617 -6.805359 -2.2919617z" fill-rule="evenodd" style="--darkreader-inline-fill: #f49985;" data-darkreader-inline-fill=""/>
    <path fill="#e16e74" d="m138.11987 203.44409l0 0c0 0.03526306 1.4104309 12.588135 -10.719307 59.62616c3.667122 0.2821045 7.54583 0.42315674 11.4950485 0.42315674c18.229874 0 37.235504 -2.67984 37.235504 -2.67984c0 -4.9365234 -4.8307495 -14.844833 -4.8307495 -14.844833c6.2411804 -9.308884 8.921005 -39.28064 8.921005 -39.28064l-42.1015 -3.2440033z" fill-rule="evenodd" style="--darkreader-inline-fill: #e16f75;" data-darkreader-inline-fill=""/>
    <path fill="#f5a594" d="m170.98253 186.48367c-0.14103699 0 -0.21156311 0 -0.21156311 0c0 0 -5.782791 0.42312622 -8.462616 6.2411804c-3.138214 5.888565 7.193222 43.476685 19.217194 48.871597c0.91677856 0.14103699 1.8688202 0.21156311 2.8561249 0.21156311c18.335663 0 47.707993 -25.775711 47.707993 -25.775711c0 0 8.074738 -0.8815155 15.26796 -2.2566986c7.6163483 -1.7277832 5.359665 -10.754578 4.513397 -12.447098c-0.35261536 -0.63468933 -2.6092987 -0.8109894 -5.465439 -0.8109894c-5.712265 0 -13.892792 0.8109894 -13.892792 0.8109894c-11.142441 2.1156616 -41.53734 18.794052 -41.53734 18.794052c0 0 -7.193222 -22.038055 -11.177689 -27.820831c-3.5966187 -5.465439 -7.8279266 -5.818054 -8.815231 -5.818054z" fill-rule="evenodd" style="--darkreader-inline-fill: #6f1c0a;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m241.11531 202.17468l0 1.8336029l11.60083 -0.56417847l0 -1.2694244z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m253.1393 204.85449l-10.719315 0.42312622l0.42312622 1.8335876l10.296188 -0.42314148l0 -1.8335724z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m244.21822 208.41583l0 1.3751526l8.497879 0l0 -1.3751526z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m245.52287 211.09563l0.42312622 1.3751373l4.936493 0l0 -1.3751373z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m190.55206 223.22528c0 1.2693939 -0.9873047 7.087448 -4.0902863 10.719315l0.9520416 0.88153076c2.15094 -2.2566986 3.561386 -4.9365234 3.9845123 -7.6163635c0.42314148 -2.2566986 0.8462677 -3.9844818 0.8462677 -3.9844818z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#e16e74" d="m171.40579 185.6022c-1.6572723 0 -2.785614 0.45838928 -2.785614 0.45838928c0 0 -15.655838 25.458374 -4.513397 26.410416c0.28208923 0.03526306 0.56417847 0.03526306 0.8462677 0.03526306c10.930878 0 22.461182 -8.110016 21.932266 -9.0620575c-0.84625244 -0.84625244 -4.8307343 -12.447098 -9.344131 -15.550049c-2.2566986 -1.8335724 -4.478134 -2.2919617 -6.135391 -2.2919617z" fill-rule="evenodd" style="--darkreader-inline-fill: #e16f75;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m153.31752 139.02287c-0.52890015 0 -0.8109894 0 -0.8109894 0c-5.359665 0.9873047 -12.1297455 4.0902557 -15.232712 11.283478c-2.6798248 7.087448 -1.4104309 12.870239 -3.5260925 27.715073c-2.2566986 15.232697 -23.765839 32.65158 -19.781357 51.868774c3.561348 15.09166 15.0563965 23.906876 29.196014 23.906876c3.878708 0 7.8984528 -0.6699524 12.059235 -2.0098724c19.217178 -6.6643066 19.64032 -18.265137 14.280655 -31.24115c-5.818039 -13.434402 -0.8815155 -15.691086 0.8462677 -26.86879c2.2566986 -11.706619 -8.497879 -12.1297455 -9.344147 -21.897018c-0.9520416 -9.449921 14.28067 -13.434402 9.767273 -23.30745c-3.7729187 -8.815231 -14.492233 -9.449921 -17.454147 -9.449921z" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m190.55196 375.2693l0 1.4104309c0 0 5.782791 0.84625244 13.822266 1.2693787l0 -1.2693787c-4.055008 0 -7.193222 -0.42312622 -9.873047 -0.88153076c-1.2693939 0 -2.2566986 -0.52890015 -2.6798248 -0.52890015z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m216.96223 292.58298c-2.6798248 5.35968 -6.770096 8.497894 -10.331436 9.767273c-1.7983093 0.9873047 -3.6318817 1.4104309 -4.513397 1.4104309c-0.8462677 0 -1.2693939 0 -1.2693939 0.42315674l-0.5289154 0l0 1.3046265c0 0 3.2087402 0 6.770096 -1.7277832c3.5260925 -1.4104309 8.462616 -4.5133667 11.142441 -10.754547l-1.2693939 -0.42315674z" fill-rule="evenodd" style="--darkreader-inline-fill: #e19cd4;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m182.90047 304.18384c-0.42314148 0.42312622 -1.3751831 0.84625244 -1.3751831 1.8335571l6.3117065 83.2157c0 0.84628296 0.45838928 1.304657 1.304657 1.304657c0.9520416 0 1.4104309 -0.88150024 1.4104309 -1.8335571l-5.818054 -83.2157c0 -0.45840454 -0.9520416 -1.304657 -1.8335571 -1.304657z" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m161.42688 304.18384c-0.84625244 0 -1.8335419 0.84625244 -1.3751526 1.304657l7.616287 87.729095c0 0.42312622 0.9520416 1.3751831 1.8335571 1.3751831c0.42312622 -0.52893066 1.2693787 -0.9520569 1.2693787 -1.7983093l-8.039413 -87.30597c0 -0.88153076 -0.88153076 -1.304657 -1.304657 -1.304657z" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill=""/>
    <path fill="#763a6b" d="m122.04108 304.18384c-0.88152313 0 -1.7277832 0.42312622 -1.7277832 1.304657l-7.616356 87.30597c0 0.84625244 0.42313385 1.2693787 1.2693939 1.7983093c0.9873047 0 1.4104385 -0.9520569 1.4104385 -1.3751831l8.039482 -87.729095c0 -0.45840454 -0.42313385 -1.304657 -1.3751755 -1.304657z" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill=""/>
    <path d="m104.62235 218.7119c-2.6798325 0 -4.9365234 2.2566986 -4.513397 4.9365234l10.754578 79.68962c0 2.1509094 2.256691 3.5260925 4.5133896 3.5260925l52.2919 0c2.6798248 0 4.9365234 -2.2567139 4.513397 -4.9365234l-10.754578 -79.12544c-0.42312622 -2.2566986 -2.2566833 -4.090271 -4.513382 -4.090271z" fill-rule="evenodd" style="--darkreader-inline-fill: #aa4e18;" data-darkreader-inline-fill="" fill="#2727AB"/>
    <path d="m132.76016 299.67044l0 7.1931763l62.164948 0c0 -3.526062 -3.102951 -7.1931763 -7.087433 -7.1931763z" fill-rule="evenodd" style="--darkreader-inline-fill: #e47d40;" data-darkreader-inline-fill="" fill="#2727AB"/>
    <path fill="#763a6b" d="m173.02747 272.83704l-0.42312622 0.42312622l47.884293 16.114227c1.304657 0.52893066 1.8335724 1.3751831 1.8335724 2.67984l0 0.9520569l-22.425934 81.41739l0.42312622 0l22.84906 -81.41739l0 -0.9520569c0 -1.304657 -0.84625244 -3.1029663 -2.6798248 -3.5613403l-47.461166 -15.655853z" fill-rule="evenodd" style="--darkreader-inline-fill: #45153c;" data-darkreader-inline-fill=""/>
  </g>
</svg>
<script lang="ts">
  import Accordion from "flowbite-svelte/Accordion.svelte"
  import AccordionItem from "flowbite-svelte/AccordionItem.svelte"
  import P from "flowbite-svelte/P.svelte"

  let className: string = "";
  export { className as class }
  export let headerClass: string = "";
  export let paragraphClass: string = "";
  export let accordionActiveClass: string = "bg-gray-300 dark:bg-gray-700";
  export let accordionInactiveClass: string = "bg-gray-200 dark:bg-gray-700";
</script>

<Accordion flush multiple class={className}>
  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      What is AddMeetings?
    </span>
    <P class={paragraphClass}>
      AddMeetings is a platform for sales professionals to request meetings with their potential clients and partners.
      One can search and filter the registered profiles, find relevant companies and positions, and request meetings using a pitch-first approach.
      There is a minimum price to request meetings, which reduces spam and increases the chances of the meeting being relevant.
    </P>
  </AccordionItem>
  
  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      What can I use AddMeetings for as a decision maker?
    </span>
    <P class={paragraphClass}>
      As a decision maker it gives you control to accept or reject meetings without sharing your personal information first. 
      When someone wants to book a meeting with you, they first have to supply a pitch and pay the meeting booking fee.
      This will reduce spam and increase the likelihood of relevant and valuable meetings.
      If you accept the request, your email will be shared with the other person in the calendar event.
    </P>
  </AccordionItem>
  
  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      Why should I put my availability here instead of just sharing my calendar?
    </span>
    <P class={paragraphClass}>
      Sharing your calendar or availability using other tools and platforms can be useful when potential clients want to reach you,
      but if booked by someone trying to sell you something, you don't get any help to determine beforehand if they are relevant.
      Because of our pitch-first approach and minimum payment requirement for meetings, you can immediately filter out the most irrelevant meetings.
    </P>
  </AccordionItem>
  
  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      How can I increase the chance of more relevant and valuable meetings?
    </span>
    <P class={paragraphClass}>
      By filling out your profile to describe what your company does, and any services that you might be looking for,
      people that want to book meetings with you can more easily determine if you are a good fit or not for their product.
      When you receive a meeting request, you can evalute the pitch and decide which ones are worth your time.
    </P>
  </AccordionItem>
  
  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      Is AddMeetings free to use?
    </span>
    <P class={paragraphClass}>
      You can register your profile and share your availability for free.
      Booking meetings with registered profiles has a minimum price of $25.
      As a user, you can adjust your own price if you want to be compensated.
    </P>
  </AccordionItem>

  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      How does AddMeetings ensure the quality of sales pitches?
    </span>
    <P class={paragraphClass}>
      There is no guarantee that the sales pitches you receive will be good or relevant to you,
      but because there is a minimum price the seller needs to pay to request a meeting with you,
      it should at least increase the chance that the product or service they offer is relevant.
      We are working on adding a feedback and rating system, but for now you can increase your price if you get too many irrelevant meetings.
    </P>
  </AccordionItem>

  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      How do I get compensated for taking meetings?
    </span>
    <P class={paragraphClass}>
      If you have chosen to get compensated for your meetings in your profile, we will send you a monthly payment certificate with invoice details.
      Your company can then send us an invoice, which we will pay within 14 days.
    </P>
  </AccordionItem>
  
  <AccordionItem classActive={accordionActiveClass} classInactive={accordionInactiveClass}>
    <span slot="header" class={headerClass}>
      How do I get started with AddMeetings?
    </span>
    <P class={paragraphClass}>
      Getting started is easy! Choose whether to log in using Google or LinkedIn to create your account.
      Once you are logged in, go to your profile to fill in your details and update your availability.
      Once your profile is set up, you will become visible on the platform and can start receiving meeting requests.
      Additionally, you can also send your AddMeetings request link for all your inbound cold meeting requests to filter out irrelevant meetings.
    </P>
  </AccordionItem>
</Accordion>

<script>
	import Heading from "flowbite-svelte/Heading.svelte";
	import P from "flowbite-svelte/P.svelte";
	import Button from "flowbite-svelte/Button.svelte";
	import Footer from "flowbite-svelte/Footer.svelte";
  import MeetingImage from '$lib/MeetingImage.svelte'
  import List from 'flowbite-svelte/List.svelte'
  import Li from 'flowbite-svelte/Li.svelte'
  import Faq from '$lib/FAQ.svelte'
</script>

<svelte:head>
  <title>AddMeetings | Save time with more relevant meetings</title>
</svelte:head>

<main class="container mx-0 sm:mx-auto mb-3 text-center">
	<Heading tag="h1" class="mt-2 sm:mt-6 sm:mb-10 px-6 sm:px-14 xl:px-60 2xl:px-80 text-gray-700 dark:text-gray-200" customSize="text-2xl sm:text-4xl md:text-5xl lg:text-6xl">
		Make your business meetings more relevant and valuable
	</Heading>
	
	<MeetingImage class="mx-6 my-4 lg:mx-auto max-w-4xl" />

	<div class="-mt-8 min-[400px]:-mt-16 min-[480px]:-mt-20 min-[600px]:-mt-24 sm:-mt-28 md:-mt-32 lg:-mt-40 text-center">
		<P class="mt-1 mb-2 text-lg lg:text-xl px-2 sm:px-8 xl:px-32 dark:text-gray-200 text-center leading-6 lg:leading-6">
			Register your profile now!
		</P>
		<Button href="https://app.addmeetings.com/login" class="w-48">Register now</Button>
	</div>

	<div class="mt-6 sm:mt-8 md:mt-12 lg:mt-20 max-w-2xl mx-4 md:mx-auto bg-gray-300 dark:bg-gray-700 rounded-lg p-4">
		<Heading tag="h2" class="text-gray-700 dark:text-gray-200" customSize="text-2xl sm:text-3xl md:text-4xl">Tired of irrelevant meetings?</Heading>
		<P class="mt-2">
			<List tag="ol" class="list-outside ps-5 space-y-1 text-gray-700 dark:text-gray-200 text-left">
				<Li>Sign up to the platform and update your profile with details and needs</Li>
				<Li>Receive meeting requests with pitches, evalute them, and accept or reject them</Li>
				<Li>Have more relevant and valuable meetings and get compensated for your time</Li>
			</List>
		</P>
	</div>

	<div class="mt-6 lg:mt-12 max-w-2xl mx-auto px-4">
		<Heading tag="h3" class="text-gray-700 dark:text-gray-200" customSize="text-xl sm:text-2xl md:text-3xl">Frequently Asked Questions</Heading>
		<Faq class="mb-1 mt-1 group-last:rounded-b-xl"
		     headerClass="text-left text-gray-700 dark:text-gray-200 font-bold" 
		     paragraphClass="text-gray-800 dark:text-gray-300" />
	</div>

	<P class="text-center my-4">
		<a class="dark:text-blue-400 text-blue-700 underline" href="/about">Read more on our about page</a>
	</P>

	<div class="mx-auto my-1 flex flex-wrap justify-center">
		<Button href="https://app.addmeetings.com/login" class="w-48">Register now</Button>
	</div>

	<Footer class="my-4 text-gray-500">
		<a href="/terms-and-conditions" target="_blank">Terms and conditions</a> | <a href="/privacy-policy" target="_blank">Privacy policy</a>
	</Footer>
</main>

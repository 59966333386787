<script lang="ts">
  import DarkMode from 'flowbite-svelte/DarkMode.svelte';
  import Navbar from "flowbite-svelte/Navbar.svelte"
  import NavBrand from "flowbite-svelte/NavBrand.svelte";
  import NavUl from "flowbite-svelte/NavUl.svelte";
  import NavLi from "flowbite-svelte/NavLi.svelte";
  import NavHamburger from "flowbite-svelte/NavHamburger.svelte";
  import AddMeetingsLogo from './AddMeetingsLogo.svelte'
  import AddMeetingsText from './AddMeetingsText.svelte'
</script>

<Navbar let:NavContainer color="primary" class="px-2 sm:px-2">
  <NavContainer class="border px-1.5 py-2 rounded-lg bg-white dark:bg-gray-700 dark:text-primary-400">
    <div class="flex flex-nowrap flex-auto">
      <NavBrand href="/" class="mx-2 me-2" title="AddMeetings">
        <AddMeetingsLogo class="w-8 h-8 stroke-current" />
        <AddMeetingsText class="w-auto h-8 ml-1" />
        <span class="sr-only">AddMeetings</span>
      </NavBrand>
    </div>
    <div class="flex sm:order-2">
      <DarkMode class="dark:hover:bg-gray-600 hidden min-[300px]:block" />
      <NavHamburger class="block sm:hidden" />
    </div>
    <NavUl divClass="w-full sm:block sm:w-auto"
       ulClass="flex flex-col py-2.5 px-4 mt-4 sm:flex-row sm:space-x-4 rtl:space-x-reverse sm:mt-0 sm:text-sm sm:font-medium text-center"
       activeClass="text-white bg-primary-700 sm:bg-transparent sm:text-primary-700 sm:dark:text-white dark:bg-primary-600 sm:dark:bg-transparent"
       nonActiveClass="text-gray-700 hover:bg-gray-100 sm:hover:bg-transparent sm:border-0 sm:hover:text-primary-700 dark:text-gray-400 sm:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white sm:dark:hover:bg-transparent">
      <NavLi class="sm:p-0" href="/about">About</NavLi>
      <NavLi class="sm:p-0" href="https://app.addmeetings.com/login">Log in</NavLi>
    </NavUl>
  </NavContainer>
</Navbar>

<script lang="ts">
  import type { HTMLButtonAttributes } from 'svelte/elements'
  interface $$Props extends HTMLButtonAttributes {
  }
</script>

<svg aria-label="AddMeetings logo" width="371" height="270" viewBox="0 0 371 270" fill="none" xmlns="http://www.w3.org/2000/svg" {...$$restProps}>
  <path d="M277.5 251L185.25 159.25M185.25 159.25L72.2264 46.2264C71.6817 45.6817 70.7516 45.9316 70.5531 46.6759L19.5633 237.888C19.3426 238.715 20.1962 239.419 20.9664 239.045L185.25 159.25Z" stroke="url(#paint0_linear_20_16)" stroke-width="38" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M352 247L238.25 133.25M238.25 133.25L125.226 20.2264C124.682 19.6817 123.752 19.9316 123.553 20.6759L72.5633 211.888C72.3427 212.715 73.1962 213.419 73.9665 213.045L238.25 133.25Z" stroke="url(#paint1_linear_20_16)" stroke-width="38" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_20_16" x1="301" y1="251" x2="19.1068" y2="232.36" gradientUnits="userSpaceOnUse">
      <stop stop-color="#536AFF"/>
      <stop offset="0.442467" stop-color="#536AFF" stop-opacity="0.1"/>
      <stop offset="0.826667" stop-color="#536AFF" stop-opacity="0.757216"/>
      <stop offset="1" stop-color="#536AFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_20_16" x1="212" y1="19" x2="72.1068" y2="196.86" gradientUnits="userSpaceOnUse">
      <stop offset="0.535" stop-color="#536AFF"/>
      <stop offset="1" stop-color="#536AFF" stop-opacity="0"/>
    </linearGradient>
  </defs>
</svg>
